import React from 'react';

const ZHC1921 = () => {
    return (
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <img src={require("../../img/iotrouter/1921-1.png")} alt="" className="iotRouterImage" />
                                <h6 className="f_p f_600 f_size_18 t_color3">ZHC 1921</h6>
                            </div>
                            <div className="info_item">
                                <h6>Communication method:</h6>
                                <p>RJ45</p>
                            </div>
                            <div className="info_item">
                                <h6>Interface type:</h6>
                                <p>RS485/DI/PIN/DO/AI</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">
                                    ZHC1921 is an industrial-grade RJ45 RTU, providing 6 circuit switching inputs, 2 circuit  high pulses, 4 circuit switching outputs, 4 circuit analog inputs and 1 circuit RS485 interface, supporting 4 circuit sockets, Modbus RTU / TCP adaptive ,
                                    Local logic processing operations, automatic collection and active reporting, DO remote follow AI / DI, configuration + cloud real-time monitoring PLC, automatic IP address acquisition,
                                    DNS domain name resolution and other special functions, support one-to-one / one-to-many / many-to-many Networking, built-in web pages to configure device parameters, enabling users to connect or debug devices on site or remotely.
                                    It can be widely used in power system, industrial monitoring, traffic management, environmental monitoring, meteorology, water conservancy, coal mine, petroleum, new energy and other industries, providing strong support for the project's communication networking and wireless transmission. </p>
                                <div className="send_email_btn"><a href="mailto:support@sensoft.tech?subject=ZHC 1921 Details" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Learn More</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt_60 ">
                    <ul className="nav nav-tabs develor_tab mb-30" id="myTab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active f_500" data-tab="tab_one" id="product_details-tab" data-toggle="tab" href="#product_details" role="tab" aria-controls="product_details" aria-selected="true">Product Details </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link f_500" data-tab="tab_two" id="product_parameter-tab" data-toggle="tab" href="#product_parameter" role="tab" aria-controls="product_parameter" aria-selected="false">Product Parameter</a>
                        </li>
                    </ul>
                    <div className="tab-content developer_tab_content">
                        <div className="tab-pane fade show active" id="product_details" role="tabpanel" aria-labelledby="product_details-tab">
                            <div className="iotrouter_details_img_container">
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC1921_details_img/01.png")} alt=""></img>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="product_parameter" role="tabpanel" aria-labelledby="product_parameter-tab">
                            <div className="iotrouter_details_img_container">
                                <h3 className="iotrouter_details_h3">Product Parameter</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ZHC1921;