import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import CustomNavbar from '../components/CustomNavbar';
import SensoftBanner from '../components/Banner/SensoftBanner';
import DomainSearch from '../components/DomainSearch';
// import HostingService from '../components/Service/HostingService';
import Company from '../components/Company/Company';
import Partners from '../components/Service/Partners';
import HostingFeatures from '../components/Service/HostingFeatures';
import HostingFeaturesTwo from '../components/Service/HostingFeaturesTwo';
// import HostingAction from '../components/HostingAction';
// import HostingPlan from '../components/Features/HostingPlan';
// import HostingMap from '../components/HostingMap';
// import HostingBlog from '../components/Blog/HostingBlog';
import HostingActionTwo from '../components/HostingActionTwo';
import FooterSensoft from '../components/Footer/FooterSensoft';
import FooterData from '../components/Footer/FooterData';

class HomeSensoft extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo" />
                <SensoftBanner />
                <Company />
                <Partners />
                <HostingFeatures />
                {/* <HostingFeaturesTwo /> */}
                <HostingActionTwo />
                <FooterSensoft FooterData={FooterData} />
            </div>
        )
    }
}
export default HomeSensoft;