import React from 'react';

const ZHT0062 = () => {
    return (
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <img src={require("../../img/iotrouter/ZHT0062-3.png")} alt="" className="iotRouterImage" />
                                <h6 className="f_p f_600 f_size_18 t_color3">ZHT 0062</h6>
                            </div>
                            <div className="info_item">
                                <h6>Communication method:</h6>
                                <p>RS485</p>
                            </div>
                            <div className="info_item">
                                <h6>Interface type:</h6>
                                <p>AI/AO</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">
                                    ZHT0062 is a serial control module that provides 6 circuit AI, 2 circuit AO and 1 circuit RS485 interfaces, supports standard Modbus RTU protocol, supports 4~20mA analog input/output, supports 12-bit sampling accuracy, 
                                    is simple and convenient to use, provides perfect Technical support, can be widely used in application scenarios such as warehousing logistics, meteorological monitoring, smart energy, aquaculture and  etc. </p>
                                <div className="send_email_btn"><a href="mailto:support@sensoft.tech?subject=ZHC 1921 Details" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Learn More</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt_60 ">
                    <ul className="nav nav-tabs develor_tab mb-30" id="myTab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active f_500" data-tab="tab_one" id="product_details-tab" data-toggle="tab" href="#product_details" role="tab" aria-controls="product_details" aria-selected="true">Product Details </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link f_500" data-tab="tab_two" id="product_parameter-tab" data-toggle="tab" href="#product_parameter" role="tab" aria-controls="product_parameter" aria-selected="false">Product Parameter</a>
                        </li>
                    </ul>
                    <div className="tab-content developer_tab_content">
                        <div className="tab-pane fade show active" id="product_details" role="tabpanel" aria-labelledby="product_details-tab">
                            <div className="iotrouter_details_img_container">
                            </div>
                        </div>
                        <div className="tab-pane fade" id="product_parameter" role="tabpanel" aria-labelledby="product_parameter-tab">
                            <div className="iotrouter_details_img_container">
                                <h3 className="iotrouter_details_h3">Product Parameter</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ZHT0062;