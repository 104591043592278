import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import IotRouterProductitem from './IotRouterProductitem';

class IotRouterProduct extends Component{
    state = {
        activeItem: '*',
    }
    
    componentDidMount() {
        var imgLoad = new ImagesLoaded('#work-portfolio');
    
        imgLoad.on('progress', function(instance, image) {
            this.iso = new Isotope('#work-portfolio', {
                itemSelector: '.portfolio_item',
                layoutMode: "masonry"
            });
        }); 
        
    }
    onFilterChange = (newFilter) => {
        
        this.setState({activeItem: newFilter});
        if (this.iso === undefined) {
            this.iso = new Isotope('#work-portfolio', {
            itemSelector: '.portfolio_item',
            layoutMode: "masonry"
            });
        }
    
    // this.iso.arrange({ filter: newFilter });   
      if(newFilter === '*') {
        this.iso.arrange({ filter: `*` });
      } else {
        this.iso.arrange({ filter: `.${newFilter}` });
      }
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';

    render(){
        return(
            <section className="portfolio_area sec_pad bg_color">
                <div className="container">
                    <div id="portfolio_filter" className="portfolio_filter mb_50">
                        <div data-filter="*" className={`work_portfolio_item ${this.onActive('*')}`} onClick={() => {this.onFilterChange("*")}}>All</div>
                        <div data-filter="rtu" className={`work_portfolio_item ${this.onActive('rtu')}`} onClick={() => {this.onFilterChange("rtu")}}>RTU</div>
                        <div data-filter="ai" className={`work_portfolio_item ${this.onActive('ai')}`} onClick={() => {this.onFilterChange("ai")}}>AI</div>
                    </div>
                    <div className="row portfolio_gallery mb_30" id="work-portfolio">
                        <IotRouterProductitem colClass="col-lg-6 rtu" pImg="4931-20.jpg" ptitle="ZHC4931" tOne="4G (Full Netcom)" tTwo="RS485/RS232/DI/DO/AI" product="ZHC4931"/>
                        <IotRouterProductitem colClass="col-lg-6 rtu" pImg="4922-12.jpg" ptitle="ZHC4922" tOne="4G (Full Netcom)" tTwo="DI/DO/AI/RS485" product="ZHC4922"/>
                        <IotRouterProductitem colClass="col-lg-6 ai" pImg="1921-14.png" ptitle="ZHC1921" tOne="RJ45" tTwo="RS485/DI/PIN/DO/AI" product="ZHC1921"/>
                        <IotRouterProductitem colClass="col-lg-6 ai" pImg="ZHT0062-2.png" ptitle="ZHT0062" tOne="RS485" tTwo="AI/AO" product="ZHT0062"/>
                    </div>
                </div>
            </section>
        )
    }
}
export default IotRouterProduct;