import React, { Component } from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import './HostingFeaturesTwo.css'

const content = [
    {
        title: "ZHC 4931",
        id: "ZHC4931",
        image: require("../../img/iotrouter/4931-20.jpg"),
    },
    {
        title: "ZHC 4922",
        image: require("../../img/iotrouter/4922-12.jpg"),
    },
    {
        title: "ZHC 1921",
        image: require("../../img/iotrouter/1921-14.png"),
    },
    {
        title: "ZHT 0062",
        image: require("../../img/iotrouter/ZHT0062-2.png"),
    },
    // Example For Putting Image For Carousel
    // {
    //     title: "Phasellus volutpat metus",
    //     description:
    //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
    //     button: "Buy now",
    //     image: require("../../img/iotrouter/1921-14.png"),
    //     user: "Bruno Vizovskyy",
    //     userProfile: "https://i.imgur.com/4KeKvtH.png"
    // }
];

class HostingFeaturesTwo extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    render() {
        return (
            <section className="hosting_features_area">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="h_features_img">
                                <Slider autoplay={3000}>
                                    {content.map((item, index) => (
                                        <a href={"iotrouterproductdetails/" + item.title.replace(' ', '')} key={index} style={{ background: `url('${item.image}') no-repeat center center`, backgroundSize: 'contain' }} >
                                            <div className="carousel-h1">
                                                <h1>{item.title}</h1>
                                            </div>
                                        </a>
                                    ))}
                                </Slider>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="h_features_content">
                                <div className="hosting_title">
                                    <h2>Products</h2>
                                    <p>We are the authorized reseller for reputable IOTRouter products by Chengdu Zongheng Intelligent Control Technology.</p>
                                </div>
                                <div className="media h_features_item">
                                    <img src={require("../../img/hosting/icon9.png")} alt="" />
                                    <div className="media-body">
                                        <h4 className="h_head">High Quality</h4>
                                        <p>Dependable and trustable product</p>
                                    </div>
                                </div>
                                <div className="media h_features_item">
                                    <img src={require("../../img/hosting/icon10.png")} alt="" />
                                    <div className="media-body">
                                        <h4 className="h_head">Product offerings</h4>
                                        <p>Friendly price can be made.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default HostingFeaturesTwo;