import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                    <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                        <div className={`container ${cClass}`}>
                            <Link className={`navbar-brand ${slogo}`} to="/">
                                <img className="sensoftLogo" src={require("../img/sensoft_white_logo.png")} alt="" />
                                <img className="sensoftLogo" src={require("../img/sensoft_logo.png")} alt="logo" />
                            </Link>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                    <li className="nav-item dropdown submenu mega_menu mega_menu_two" data-toggle="collapse" data-target="#navbarSupportedContent">
                                        <a href="/" className="nav-link dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">Home</a>
                                    </li>
                                    <li className="dropdown submenu nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                                        <a href="/#company" className="dropdown-toggle nav-link" role="button" aria-haspopup="true" aria-expanded="false">Company</a>
                                        {/* <Link to="/#company" title="Pages" className="dropdown-toggle nav-link" role="button" aria-haspopup="true" aria-expanded="false" >Company</Link> */}
                                    </li>
                                    <li className="dropdown submenu nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                                        <a href="/#partners" title="Pages" className="dropdown-toggle nav-link" role="button" aria-haspopup="true" aria-expanded="false">Partners</a>
                                        <ul role="menu" className=" dropdown-menu">
                                            <li className="nav-item"><a href="https://my.alibabacloud.com" alt="Alibaba Cloud" className="nav-link" target="blank">Alibaba Cloud</a></li>
                                            <li className="nav-item"><a href="https://iotrouter.com" alt="IoT Router" className="nav-link" target="blank">IoT Router</a></li>
                                            <li className="nav-item"><a href="https://www.urbanfarmtech.my" alt="Urban Farm Tech" className="nav-link" target="blank">Urban Farm Tech</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown submenu nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                                        <a href="/#solutions" className="dropdown-toggle nav-link" role="button" aria-haspopup="true" aria-expanded="false" > Solutions</a>
                                        {/* <Link to="./" title="Pages" className="dropdown-toggle nav-link" role="button" aria-haspopup="true" aria-expanded="false">Solutions</Link> */}
                                    </li>
                                    {/* <li className="nav-item dropdown submenu">
                                    <a className="nav-link dropdown-toggle" href=".#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Blog
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><NavLink to="/Bloglist" className="nav-link">Blog List</NavLink></li>
                                        <li className="nav-item"><NavLink to="/BlogGridPage" className="nav-link">Blog Grid</NavLink></li>
                                        <li className="nav-item"><NavLink to="/BlogSingle" className="nav-link">Blog Single</NavLink></li>
                                    </ul>
                                </li> */}
                                    <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent"><NavLink title="Contact" className="nav-link" to="/Contact">Contact</NavLink></li>
                                </ul>
                                {/* <a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a> */}
                            </div>
                        </div>
                    </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;