import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb_iot from '../components/Breadcrumb_iot';
import Portfolios from '../components/Portfolios/Portfolio2grid';
import FooterSensoft from '../components/Footer/FooterSensoft';
import FooterData from '../components/Footer/FooterData';
import IotRouterProduct from '../components/Portfolios/IotRouterProduct';

const IotRouter = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo" />
            <Breadcrumb_iot breadcrumbClass="breadcrumb_iot_area" imgName="breadcrumb/iotrouter_bg.jpg" titleImg="iotrouter_white_logo.png" Pdescription="IOTRouter products are developed by Chengdu Zongheng Intelligent Control Technology." />
            <IotRouterProduct />
            <FooterSensoft FooterData={FooterData}/>
        </div>
    )
}
export default IotRouter;