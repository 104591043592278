import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterSensoft from '../components/Footer/FooterSensoft';
import FooterData from '../components/Footer/FooterData';

const About = () => {
    return(
        <div className="body_wrapper">
           <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/contact_banner.jpg" Ptitle="Contact Us" Pdescription="We welcome any enquiry or feedback regarding our products, services or company."/>
            <Contacts/>
            <FooterSensoft FooterData={FooterData}/>
        </div>
    )
}
export default About;